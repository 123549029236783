import { useTranslation } from '~/i18n'

const withLocale = (namespacesDefault, keyDefault) => (Component) => ({
  tNamespaces = namespacesDefault,
  tKey = keyDefault,
  ...props
}) => {
  const { t } = useTranslation(tNamespaces)
  const key = tKey || tNamespaces

  const translations = t(key, { returnObjects: true })

  return <Component {...translations} {...props} />
}

export default withLocale
